@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600&family=Montserrat:wght@600&display=swap');
.monte{
    font-family: 'Montserrat', sans-serif!important;
    font-weight: 600;
}
.monte__300{
    font-family: 'Montserrat', sans-serif!important;
    font-weight: 300;
}
.josefin{
  font-family: 'Josefin Sans', sans-serif!important;
  font-weight: 600;

}
@media screen and (max-width: 500px) {

  /* input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] ,
input[type="text"] ,
input[type="email"],
select 
{
    background: transparent!important;
    
  
} */
.ant-input:placeholder-shown {
  font-size: 13px!important;
}
/* .hero-image {
  background-image: linear-gradient(rgb(3 5 48 / 0%), rgba(0, 0, 0, 0.5)),
        url("../images/ikp.png");

  height: 61vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 23px;
} */
.hero-image {
  /* background-image: linear-gradient(rgb(3 5 48 / 0%), rgba(0, 0, 0, 0.5)),
      url("../images/ikknew.png"); */
      background-image: linear-gradient(rgb(3 5 48 / 0%), rgb(0 0 0 / 3%)),
      url("../images/hrMobile.png");
  /* background-image: linear-gradient(rgba(3, 5, 48, 0.5), rgba(69, 51, 51, 0.5)),
      url("../images/IKONNEHEROMOBILE.png"); */
  height: 61vh;
  /* width: 100vw; */
  /* float: left; */
  /* transform: scaleX(-1); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* position: relative; */
  margin-top: 23px;
  /* margin-top: -171px; */
} 
      .hero-image2 {
     
        background-image: linear-gradient(rgba(3, 5, 48, 0.5), rgba(0, 0, 0, 0.5)),
          url("../images/senate.jpeg");


          /* background-image: linear-gradient(rgba(3, 5, 48, 0.5), rgba(69, 51, 51, 0.5)),
          url("../images/IKONNEHEROMOBILE.png"); */


          /* background-image: linear-gradient(rgba(3, 5, 48, 0.5), #0b0ed900),  url("../images/IKONNEHEROMOBILE.png"); */

          /* background-image: linear-gradient(rgba(3, 5, 48, 0.5), rgba(0, 0, 0, 0.5)),
          url("../images/IKONNEHEROMOBILE.png"); */
          
          /* url("../images/WhatsApp Image 2022-06-12 at 4.39.44 PM (5) copy.jpeg"); */
      
      
        /* Set a specific height */
        height: 100vh;
        /* width: 100vw; */
        /* float: left; */
        /* Position and center the image to scale nicely on all screens */
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        /* position: relative; */
        margin-top: 0;
  }


    
  .hero-image3 {
     
    background-image: linear-gradient(rgba(3, 5, 48, 0.5), rgba(0, 0, 0, 0.5)),
      url("../images/glass.jpeg");

    height: 100vh;
    /* width: 100vw; */
    /* float: left; */
    /* Position and center the image to scale nicely on all screens */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* position: relative; */
    margin-top: 0;
}
}


/* Fixed/sticky icon bar (vertically aligned 50% from the top of the screen) */
.icon-bar {
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 99;
  right: 0;
  width: 31px;
}

/* Style the icon bar links */
.icon-bar a {
  display: block;
  text-align: center;
  padding: 10px;
  transition: all 0.3s ease;
  color: white;
  font-size: 12px;
}

/* Style the social media icons with color, if you want */
.icon-bar a:hover {
  background-color: #000;
}

.facebook {
  background: #3B5998;
  color: white;
}

.twitter {
  background: #55ACEE;
  color: white;
}

.google {
  background: #dd4b39;
  color: white;
}

.linkedin {
  background: #d5d5d5;
  color: white;
}

.youtube {
  background: #bb0000;
  color: white;
}

.ant-upload.ant-upload-select-picture-card {
  background-color: transparent!important;
}

td{
  font-size: 11px!important;
}

.ant-modal-wrap {
  z-index: 99999999!important;
}