
.outline-all {
    border: lightgray thin solid !important;
}

/** Padding and Margin**/
.no-padding {
    padding: 0 !important;
}

.no-margin {
    margin: 0 !important;
}

.tiny-padding {
    padding: 0.5em;
}

.tiny-padding-top {
    padding-top: 0.5em;
}

.tiny-padding-bottom {
    padding-bottom: 0.5em;
}

.padding-1em {
    padding: 1em;
}

.padding-top-1em {
    padding-top: 1em !important;
}

.padding-btm-1em {
    padding-bottom: 1em !important;
}

.padding-top-2em {
    padding-top: 2em !important;
}

.padding-btm-2em {
    padding-bottom: 2em !important;
}

.padding-top-4em {
    padding-top: 4em !important;
}

.padding-btm-4em {
    padding-bottom: 4em !important;
}

.tiny-margin {
    margin: .5em;
}

.tiny-margin-top {
    margin-top: .5em;
}

.tiny-margin-bottom {
    margin-bottom: .5em;
}

.margin-1em {
    margin: 1em;
}

.margin-btm-1em {
    margin-bottom: 1em !important;
}

.margin-top-1em {
    margin-top: 1em !important;
}

.margin-btm-2em {
    margin-bottom: 2em !important;
}

.margin-top-2em {
    margin-top: 2em !important;
}

.margin-btm-4em {
    margin-bottom: 4em !important;
}

.margin-top-4em {
    margin-top: 4em !important;
}


/*Fonts*/
.font-sm {
    font-size: .8em !important;
}

.font-lg {
    font-size: 2em !important;
}

.font-xl {
    font-size: 3em !important;
}

.font-bold {
    font-weight: 700;
}

/*Depth*/
.z-depth-0, .z-depth-0-hover:hover {
    box-shadow: none !important;
}

.z-depth-1, .z-depth-1-hover:hover, .card-panel, .card, .panel, .toast, .dropdown-content, .collapsible {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.z-depth-1-half, .z-depth-1-half-hover:hover, .card:hover, .panel:hover, tbody > tr:hover {
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
}

.z-depth-2, .z-depth-2-hover:hover {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
}

.z-depth-3, .z-depth-3-hover:hover {
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.3);
}

.z-depth-4, .z-depth-4-hover:hover, .modal {
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.3);
}

.z-depth-5, .z-depth-5-hover:hover {
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.3);
}
.container-login100 {
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
}
.container-login100::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    /* background: #0f1011; */
    background: -webkit-linear-gradient(bottom, #131010, #000000);
    font-family: 'Montserrat', sans-serif!important;
    font-weight: 600;
    /* background: linear-gradient(bottom, #131010, #000000)!important; */
    /* background: -o-linear-gradient(bottom, #005bea, #00c6fb);
    background: -moz-linear-gradient(bottom, #005bea, #00c6fb);
    background: linear-gradient(bottom, #005bea, #00c6fb); */
    opacity: 0.9;
}

.input100 {
    /* font-family: Montserrat-Bold; */
    /* font-size: 13px!important; */
    font-family: 'Montserrat', sans-serif!important;

    line-height: 1.2;
    color: #333333;
    display: block;
    width: 100%;
    background: #fff;
    height: 50px;
    border-radius: 25px;
    padding: 0 30px 0 53px;
}
.login100-form-title {
    /* font-family: Montserrat-ExtraBold; */
    font-family: 'Montserrat', sans-serif!important;
    font-size: 24px;
    color: #fff;
    line-height: 1.2;
    text-align: center;
    width: 100%;
    display: block;
}
.login100-form {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.login100-form-avatar {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
}
.wrap-login100 {
    width: 390px;
    background: transparent;
}
.limiter {
    width: 100%;
    margin: 0 auto;
}
.alert-validate::before {
    content: attr(data-validate);
    position: absolute;
    max-width: 70%;
    background-color: white;
    border: 1px solid #c80000;
    border-radius: 14px;
    padding: 4px 25px 4px 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 8px;
    pointer-events: none;
    font-family: Montserrat-Bold;
    color: #c80000;
    font-size: 13px;
    line-height: 1.4;
    text-align: left;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity 0.4s;
    -o-transition: opacity 0.4s;
    -moz-transition: opacity 0.4s;
    transition: opacity 0.4s;
}
.alert-validate::after {
    content: "\f06a";
    font-family: FontAwesome;
    display: block;
    position: absolute;
    color: #c80000;
    font-size: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 13px;
}
.alert-validate::before {
    visibility: visible;
    opacity: 1;
}
.validate-input {
    position: relative;
}
.wrap-input100 {
    position: relative;
    width: 100%;
    z-index: 1;
}
.focus-input100 {
    display: block;
    position: absolute;
    border-radius: 25px;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 0px 0px;
    color: rgba(0,91,234, 0.6);
}
.symbol-input100 {
    font-size: 15px;
    color: #999999;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    position: absolute;
    border-radius: 25px;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-left: 30px;
    pointer-events: none;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}
.input100 {
    font-family: Montserrat-Bold;
    font-size: 15px;
    line-height: 1.2;
    color: #333333;
    display: block;
    width: 100%;
    background: #fff;
    height: 50px;
    border: none!important;
    border-radius: 25px;
    padding: 0 30px 0 53px;
}
.container-login100-form-btn {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.login100-form-btn {
    font-family: Montserrat-Bold;
    font-size: 15px;
    line-height: 1.5;
    color: #e0e0e0;
    width: 100%;
    height: 50px;
    border-radius: 25px;
    background: #333333;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 25px;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
    position: relative;
    z-index: 1;
}

/* @media screen and (max-width: 576px) {
    .wrap-login100 {
        padding-top: 80px;
        padding-left: 15px;
        padding-right: 15px;
    }
    
} */